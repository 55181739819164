import React,{Suspense} from 'react'
import Loading from './components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const WhoWeServe = React.lazy(() => import('./pages/WhoWeServe'));
const EPrescription = React.lazy(() => import('./pages/EPrescription'));
const DigitalMarketing = React.lazy(() => import('./pages/DigitalMarketing'));
const ReputationManagement = React.lazy(() => import('./pages/ReputationManagement'));
const EMR = React.lazy(() => import('./pages/EMR'));
const Appointment = React.lazy(() => import('./pages/Appointment'));
const StaffManagement = React.lazy(() => import('./pages/StaffManagement'));
const Contact = React.lazy(() => import('./pages/Contact'));
const RequestDemo = React.lazy(() => import('./pages/RequestDemo'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))
const FAQs = React.lazy(() => import('./pages/Faq'))
const AppDev = React.lazy(() => import('./pages/AppDev'))
const NotFound = React.lazy(() => import('./pages/404'))

const App = () => {
    return (
        <>
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<About />}/>
                <Route path="/who-we-serve" element = {<WhoWeServe/>} />
                <Route path="/e-prescription" element = {<EPrescription/>} />
                <Route path="/digital-marketing" element = {<DigitalMarketing/>} />
                <Route path="/reputation-management" element = {<ReputationManagement/>} />
                <Route path="/emr-solution" element = {<EMR/>} />
                <Route path="/appointment-scheduling" element = {<Appointment/>} />
                <Route path="/staff-management" element = {<StaffManagement/>} />
                <Route path="/app-development" element = {<AppDev/>} />
                <Route path='/contact-us' element={<Contact />}/>
                <Route path='/request-demo' element={<RequestDemo />}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/faq" element={<FAQs/>} />
                <Route path="*" element={<NotFound/>}  />

            </Routes>
            <ToastContainer 
                position="bottom-left"
                autoClose={5000}
                />
        </Suspense>
        
        </>
    )
}

export default App
